import services from "@/services/services";
import axios from '@/plugins/axios'
import { Day } from "@/interfaces/Program/Day";
import { Presentation } from "@/interfaces/Program/Presentation";

class ProgramService extends services {

  days(eventoId: string|number): Promise<Day[]>  {

    const promesa = axios.get('eventos/' + eventoId +'/dias',{ data: { noCapturar: true } })

    return promesa.then((respuesta)=>{
      return respuesta.data
    }).catch((e) => {
      console.info(e)
      throw new Error('Error')
    })
  }

  presentations(eventoId: string|number, diaId: string|number): Promise<{
    fecha: string;
    dia: string;
    presentaciones: Presentation[];
  }>  {

    const promesa = axios.get('eventos/' + eventoId +'/dias/' + diaId + '/actividades', { data: { noCapturar: true } })

    return promesa.then((respuesta)=>{
      return respuesta.data

    }).catch((e) => {
      console.info(e)
      throw new Error('Error')
    })
  }

  presentation(eventoId: string|number, presentationId: string|number): Promise<Presentation>  {

    const promesa = axios.get('eventos/' + eventoId + '/actividades/' + presentationId, { data: { noCapturar: true } })

    return promesa.then((respuesta)=>{
      return respuesta.data
    }).catch((e) => {
      console.info(e)
      throw new Error('Error')
    })
  }
}

export const programService = new ProgramService()