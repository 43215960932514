<template>
  <ion-page>
    <ion-content fullscreen>
      <TheHeaderLarge subseccion="menu.agenda" />
      <TheList :items-list="days" />
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonPage,
} from '@ionic/vue';
import { defineComponent, ref, onMounted } from 'vue';
import TheHeaderLarge from "@/components/TheHeaderLarge.vue";
import TheList from "@/components/List/TheList.vue";
import { useRoute } from "vue-router";
import { programService } from "@/services/program.service";
import { ItemsList } from "@/components/List/ItemList";
import usePushLink from "@/composables/usePushLink";


export default defineComponent({
  name: 'Days',
  components: {
    TheList,
    TheHeaderLarge,
    IonContent,
    IonPage
  },
  setup() {

    const days = ref<ItemsList[]>([])
    const route = useRoute()
    const eventId = route.params.eventId.toString()
    const { getUrl } = usePushLink()

    onMounted(async ()=> {
      const daysData = await programService.days(parseInt(eventId))

      days.value = daysData.map((day) => {
        return {
          title: day.dia,
          link: getUrl('Presentations', { eventId: eventId, dayId: day.id.toString() }),
          subtitle: day.fecha
        }
      })
    })

    return {
      days
    }
  }
});
</script>

<style scoped>
#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;

  color: #8c8c8c;

  margin: 0;
}

#container a {
  text-decoration: none;
}
</style>